/** @format */

import React from "react";
import {Link} from "react-router-dom"

const NewsLetter = () => {
	return (
		<div className="container mx-auto ">
			<div className="md:min-h-screen mt-5 md:mt-0 mb-5 md:mb-0 w-screen flex flex-col justify-center items-center">
				<div className="w-5/6 md:w-8/12">
					<div class="card md:p-5 w-full bg-cyan-900 text-neutral-content rounded-lg">
						<div class="card-body w-full md:items-center md:text-center md:py-10">
							<h3 className="card-title text-base">
								Want updates straight in your inbox?
							</h3>
							<p className="mt-6 text-sm">
								Enter your email to get the latest news and announcements from
								Pay<span className="font-bold">Africa</span>
							</p>
							<div className="w-full max-w-2xl flex flex-col md:flex-row justify-center gap-4 py-4">
								<form className="md:p-2 w-full" action="">
									<div className="mt-2 md:w-full flex flex-col md:flex-row justify-center gap-4">
										<input
											className="input w-full max-w-xs shadow appearance-none border rounded-md py-5 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline md:text-center"
											type="text"
											placeholder="First Name"
											required
										/>
										<input
											className="input w-full max-w-xs shadow appearance-none border rounded-md py-5 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline md:text-center"
											type="text"
											placeholder="Last Name"
											required
										/>
									</div>
									<div className="mt-4 w-full">
										<input
											className="input w-full max-w-full shadow appearance-none border rounded-md py-5 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline md:text-center"
											type="email"
											placeholder="Enter your email address"
											required
										/>
									</div>
								</form>
							</div>
							<div className="mt-4 w-full">
								<Link to="/">
									<button
										className="w-full max-w-sm bg-black hover:opacity-50  text-white py-3 px-4 md:py-4 md:px-5 rounded-md focus:outline-none focus:shadow-outline text-center"
										type="button">
										Subscribe
									</button>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewsLetter;
