/** @format */
import React, { useState, useEffect } from "react";
import { socket } from "../../App";
import construct from "../../cuate.svg";

const Hero = () => {
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [loading, setLoading] = useState(false);
  const [serverResponse, setServerResponse] = useState({});
  const [isServerResponseActive, setisServerResponseActive] = useState(false);

  useEffect(() => {
    
  }, [])

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  }
  const onChangePhone = (e) => {
    setPhone(e.target.value);
  }
  const onSubmitContact = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      email,
      phone
    }
    socket.emit('getContact', data);
    console.log('sent socket')
  }
  socket.on(socket.id + 'contactError', error => {
    console.log('error', error);
    setServerResponse(error);
    setisServerResponseActive(true);
    setLoading(false);
  });
  socket.on(socket.id + 'contactResponse', response => {
    setServerResponse(response);
    setisServerResponseActive(true);
    setLoading(false);
    console.log('response', response);
  });
  return (
    <div className="container mx-auto bg-primaryColor"	>
      <div className="mx-4 mt-5">
        <div className="grid grid-cols-1 md:grid-cols-3 ">
          <div className=" flex flex-col justify-center">
            <h1 className="text-4xl font-bold text-white">Web App</h1>
            <h1 className="text-4xl font-bold mt-4 text-white">Coming Soon</h1>

            {/* for smaller devices */}
            <div className="mt-5 col-span-2 container  mx-auto flex flex-col justify-center items-center">
              <img
                src={construct}
                className="w-10/12 h-10/12 md:hidden"
                alt="cuate"
              />
            </div>
            {/* end */}

            <p className="py-6 w-80 text-sm text-white">
              A lot of new exciting stuff is coming your way get notified when
              the app is ready simply enter your details and we’ll notify you?.
            </p>
            <p className="text-sm text-gray-300">
              We promise not to span your inbox
            </p>
            <div className="w-full max-w-sm">
              <form className="mt-2" method='post' action='#' onSubmit={onSubmitContact}>
                <div className="">
                  <input
                    className="text-sm shadow appearance-none border rounded-sm w-10/12 py-2 px-2 md:py-3 md:px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline text-left"
                    type="email"
                    placeholder="Email Address (Optional)"
                    name='email'
                    value={email || ""}
                    onChange={onChangeEmail}
                    required
                  />
                  <input
                    className="text-sm shadow appearance-none border rounded-sm w-10/12 py-2 px-2 md:py-3 md:px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline text-left"
                    type="text"
                    placeholder="Phone Number (Optional)"
                    name='phone'
                    value={phone || ""}
                    onChange={onChangePhone}
                    required
                  />
                  {isServerResponseActive && (
                    <div class={serverResponse.success ? "bg-green-100 border border-green-400 text-green-700 w-10/12 py-2 px-2 md:py-3 md:px-3 rounded relative" : "bg-red-100 border border-red-400 text-red-700 w-10/12 py-2 px-2 md:py-3 md:px-3 rounded relative"} role="alert">
                  <strong class="font-bold">{serverResponse.success ? "Hurray🥳" : "Holy smokes!🥲"}</strong>
                  <span class="block sm:inline">{serverResponse.message}</span>
                      <span onClick={()=>{
                        setServerResponse("");
                        setisServerResponseActive(false);
                      }} class="absolute top-0 bottom-0 right-0 py-2 px-2 md:py-3 md:px-3">
                        <svg class="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                      </span>
                    </div>
                  )}
                </div>
                <div className="mt-2">
                  {loading ? (
                    <>
                      <div className="flex">
                        <div className="h-2.5 w-2.5 bg-current rounded-full mr-1 animate-bounce"></div>
                        <div className="h-2.5 w-2.5 bg-current rounded-full mr-1 animate-bounce200"></div>
                        <div className="h-2.5 w-2.5 bg-current rounded-full animate-bounce400"></div>
                      </div>
                    </>
                  ) : (
                      <button
                        className="bg-buttonColor hover:opacity-50 w-5/12 text-white font-bold py-2 px-2 md:py-3 md:px-3 rounded-sm focus:outline-none focus:shadow-outline text-center"
                        type="submit">
                        Get Notified
                      </button>
                  )}
                </div>
              </form>
            </div>
          </div>

          <div className="hidden col-span-2 container  mx-auto md:flex flex-col justify-center items-center">
            <img src={construct} className="w-10/12 h-10/12" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Hero;
