import React from "react";
import map from "../../map.png";
const Hero = () => {
  return (
		<div
			className="container mx-auto"
			// className="hero min-h-screen"
		>
			<div className="mx-auto">
				<div className="grid grid-cols-1 mt-5 md:grid-cols-3 md:mt-0 ">
					<div className=" flex flex-col justify-center">
						<h1 className="mb-10 font-bold text-4xl text-left">
							Need a First, secure and Convenient{" "}
							<span className="text-primaryColor">Loan.</span>
						</h1>
						<p className="py-6 w-80 text-md flex flex-wrap md:text-left md:text-xl">
							Pay Africa is the easiest way to apply for a convenient Loan. With
							An easy 3 Step Process, are you ready to get credit disbursed onto
							you Mobile money account.{" "}
						</p>
						<div className="mt-7 mb-5 flex justify-center md:mb-0">
							<button className="w-10/12 py-2 px-4 rounded-md md:hover:opacity-50 md:w-full text-white font-bold md:py-5 md:px-5 md:rounded-xl focus:outline-none focus:shadow-outline text-center bg-primaryColor  ">
								Sign Up for free
							</button>
						</div>
					</div>

					<div className="hidden md:p-2 md:col-span-2 md:container  md:mx-auto md:flex md:flex-col md:justify-center md:items-center">
						<img src={map} className="w-full h-full" alt="" />
					</div>
				</div>
			</div>
		</div>
	);
};
export default Hero;
