/** @format */

import React from "react";
import Cards from "./Cards";

const Custodian = () => {
	return (
		<div className="container mx-auto md:min-h-screen">
			<div className="mt-5 px-4 mb-5 md:mb-0">
				<h1 className="text-xl">
					The <span className="font-bold">Custodian</span>
				</h1>
				<div className="mt-5 flex justify-center md:mt-0 md:mx-8 md:p-24">
					<Cards />
				</div>
			</div>
		</div>
	);
};

export default Custodian;
