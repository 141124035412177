/** @format */

import React from "react";
import NavBar from "../../components/Blog/NavBar";
import Hero from "../../components/Blog/Hero";
// import All from '../../components/Blog/All'
// import BlogTop from '../../components/Blog/BlogTop'
import Cards from "../../components/Blog/Cards";
import NewsLetter from "../../components/Blog/NewsLetter";
import Footer from "../../components/shared/Footer";

const Blog = () => {
	return (
		<div>
			<NavBar />
			<div>
				<div className="divide-y-2 divide-solid divide-gray-200">
					<Hero />
					<Cards />
					<NewsLetter />
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Blog;
