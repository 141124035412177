/** @format */

import React from "react";

const Cards = () => {
	return (
		<div className="flex flex-col space-y-4 md:space-y-0 md:grid md:grid-cols-3 md:gap-3  md:justify-center md:mt-3 ">
			{/* Card one */}
			<div className=" w-80 h-52 grid text-white place-content-center shadow-xl bg-primaryColor rounded-t-lg rounded-l-lg">
				<div className="card-body ">
					<span className="card-title place-content-center ">
						<img className="h-3/12 w-3/12" src="/images/settings.png" alt="" />
					</span>
					<h3 className="font-bold">Manage my account</h3>
					<div className="">
						<p className="">12 articles</p>
					</div>
				</div>
			</div>

			{/* Card two */}
			<div className=" w-80 h-52 grid text-black place-content-center shadow-xl bg-white rounded-t-lg border-2">
				<div className="card-body ">
					<span className="card-title place-content-center ">
						<img className="h-4/12 w-4/12" src="/images/flag.png" alt="" />
					</span>
					<h3 className="font-bold">Getting Started</h3>

					<div className="">
						<p className="">12 articles</p>
					</div>
				</div>
			</div>

			{/* Card three */}
			<div className=" w-80 h-52 grid text-white place-content-center shadow-xl bg-primaryColor rounded-t-lg rounded-r-lg">
				<div className="card-body ">
					<span className="card-title place-content-center ">
						{" "}
						<img
							className="h-4/12 w-4/12"
							src="/images/dollar-sign.png"
							alt=""
						/>
					</span>{" "}
					<h3 className="font-bold">Transactions</h3>
					<div className="">
						<p className="">12 articles</p>
					</div>
				</div>
			</div>

			{/* Card four */}
			<div className=" w-80 h-52 grid text-black place-content-center shadow-xl bg-white rounded-b-lg rounded-l-lg border-2">
				<div className="card-body ">
					<span className="card-title place-content-center ">
						{" "}
						<img className="h-4/12 w-4/12" src="/images/lock.png" alt="" />
					</span>{" "}
					<h3 className="font-bold">Privacy & Policy</h3>
					<div className="">
						<p className="">12 articles</p>
					</div>
				</div>
			</div>

			{/* Card five */}
			<div className=" w-80 h-52 grid text-white place-content-center shadow-xl bg-primaryColor rounded-b-lg">
				<div className="card-body ">
					<span className="card-title place-content-center ">
						{" "}
						<img className="h-4/12 w-4/12" src="/images/briefcase.png" alt="" />
					</span>{" "}
					<h3 className="font-bold">Other Topics</h3>
					<div className="">
						<p className="">12 articles</p>
					</div>
				</div>
			</div>

			{/* Card six */}
			<div className=" w-80 h-52 grid text-black place-content-center shadow-xl bg-white rounded-b-lg rounded-r-lg border-2">
				<div className="card-body ">
					<span className="card-title place-content-center ">
						{" "}
						<img
							className="h-4/12 w-4/12"
							src="/images/phone-call.png"
							alt=""
						/>
					</span>
					<h3 className="font-bold">Contact Us</h3>

					<div className="">
						<p className="">12 articles</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Cards;
