import React from 'react'

const SignUpVerify = () => {
  return (
		<div className="grid grid-cols-1 md:grid-cols-2">
			<div className="mt-10 h-screen flex flex-col justify-center items-center">
				<h1 className="text-3xl">
					Pay<span className=" font-bold">Africa</span>
				</h1>
				<h3 className="text-lg mt-11 font-bold">Sign in to your account</h3>
				<p className="text-md mt-3">
					A verification code has been sent to you email
				</p>

				<div className="w-full max-w-sm mb-40">
					<form className=" p-4 mt-5" action="">
						<div className="mt-4 flex flex-col justify-center items-center">
							<input
								className="shadow appearance-none border rounded-md md:rounded-xl w-4/6 md:w-full py-3 px-3 md:py-5 md:px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline text-center"
								id="email"
								type="email"
								placeholder="_ _ _ _"
								required
							/>
							<button
								className="bg-primaryColor hover:opacity-50 w-4/6 md:w-full text-white font-bold py-3 px-3 md:py-5 md:px-5 rounded-md md:rounded-xl focus:outline-none focus:shadow-outline text-center"
								type="button">
								Verify
							</button>
						</div>
					</form>
				</div>

				<div className=" grid grid-cols-3 gap-8 md:gap-32">
					<div>
						<a href="/#aboutus">About Us</a>
					</div>
					<div>
						<a href="/">Privacy Policy</a>
					</div>
					<div>
						<a href="/">Terms of Use</a>
					</div>
				</div>
			</div>
			<div
				className="hidden md:flex flex-col justify-center items-center h-full w-full bg-contain bg-center bg-blend-overlay bg-primaryColor"
				style={{ backgroundImage: `url(/images/bg.jpg)` }}>
				<div className="mb-4">
					<h1 className="text-white text-3xl">
						Pay<span className="font-bold">Africa</span>
					</h1>
				</div>
				<div>
					<p className="text-white text-xl text-center">
						The easiest way to apply for a <br />
						convient Loan. With an easy 3 step <br />
						process. Are you ready to get credit <br />
						disbursed onto your mobile money <br />
						account
					</p>
				</div>
			</div>
		</div>
	);
}

export default SignUpVerify