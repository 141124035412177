
import React from 'react'

const Procedure = () => {
  
  const items = [
		{
			step: "step 01",
			h2: "Sign",
			span: "Up",
			p: "Simply use your your Phone Number and receive a verification code to complete this stage",
		},
		{
			step: "step 02",
			h2: "Complete",
			span: "Registration",
			p: "Fill in the relevant fields with your Personal, Work and Family Information to complete this stage",
		},
		{
			step: "step 03",
			h2: "Acquire",
			span: "Loan",
			p: "Check Your Credit Worth and Apply for a loan and receive your disbursement once successfull",
		},
	];

  return (
		<div className="container  mx-auto">
			<div className="mt-5">
				<h2 className=" text-xl">
					The <span className=" font-bold">Procedure</span>
				</h2>
				<div className="mt-5 mb-0 md:mb-10 md:mt-10 md:px-20">
					<div className="flex flex-col lg:flex-row">
						{items.map((item) => (
							<div className="card mx-8 mt-3 mb-3">
								<div>
									<figure className="px-10 pt-0 ">
										<div className="border border-black rounded-full h-24 w-24 grid place-content-center">
											{item.step}
										</div>
									</figure>
									<div className="card-body items-center text-center">
										<h2 className="card-title font-normal">
											{item.h2} <span className="font-bold">{item.span}</span>
										</h2>
										<p className="w-15 items-center text-center">{item.p}</p>
									</div>
								</div>
							</div>
						))}
					</div>
					<br />
				</div>
			</div>
		</div>
	);
}

export default Procedure

