/** @format */
import React from "react";
import IO from 'socket.io-client';
import Routes from "./utils/Routes/Routes"
import { useEffect } from 'react';

export const socket = IO('http://localhost:5600', {
  forceNew: true,
});

const App = () => {
	return (
		<div>
			<Routes />
		</div>
	);
}

export default App;
