/** @format */

import React from "react";
//import {coin, loan, score, wallet} from './img'
const Features = () => {
	const items = [
		{
			icon: (
				<img className="h-auto w-auto p-5" src="/images/wallet.png" alt="" />
			),
			heading: "Your Wallet",
			p: "PayAfrica believes there’s no better way to manage  your account other than being able to withdraw and deposit funds at the comfort of your Cellular phone.",
		},
		{
			icon: (
				<img className="h-auto w-auto p-5" src="/images/loan1.png" alt="" />
			),
			heading: "Give A Loan",
			p: "PayAfrica allows you to fully participate in our cause to make funds easily accessible to those in need on this platform. Through Your Wallet You can invest by giving out a loan.",
		},
		{
			icon: (
				<img className="h-auto w-auto p-5" src="/images/loan2.png" alt="" />
			),
			heading: "Get A Loan",
			p: "PayAfrica strongly stands with this as our core value, are you verified, facing a certain nature of a short coming but believe you will be able to pay back in the required time, Get A Loan!",
		},
		{
			icon: <img className="h-auto w-auto p-5" src="/images/fire.png" alt="" />,
			heading: "Credit Score",
			p: "PayAfrica believes that games can be motivating a times and an easy way to relieve your stress while sharpening your mind. Simply pay in time rather than on time to discover the Fun.",
		},
	];

	return (
		<div id="features" className="container mx-auto">
			<div className="mt-5 mb-10">
				<h1 className="text-xl">
					The <span className="font-bold">Features</span>
				</h1>
				{items.map((item) => (
					<div className="mt-5  md:w-240">
						<div className="md:mx-7 hero-content flex-col md:flex-row ">
							<div className="">
								<span className="rounded-md md:h-40 md:w-40 grid place-content-center bg-primaryColor text-8xl font-extrabold">
									{item.icon}
								</span>
							</div>
							<div className="flex flex-col items-center md:items-start">
								<h4 className="text-2xl underline underline-offset-2 decoration-primaryColor">
									{item.heading}
								</h4>
								<p className="py-6">{item.p}</p>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Features;
