/** @format */

import React from "react";
import { Link } from "react-router-dom";
import blogImg from '../../cuate.svg'

const Cards = () => {
	const items = [
		{
			title: "PayAfrica",
			span: "News",
			p: "Lörem ipsum läktigt ödleplåster. Nir hirade sesaskap udir. Milätt krodåren byväns nijöning. Astrock nyvartad: kvasifoni. Polyr ultrament. Jåskade gensax, rede i läplade att pekäv. Mägon epifaktisk alltså rede. Miteren heminyhet. Terudat kafaliga. Bel plande, reack. Dertad äfanera även om konar i krotide. ",
		},
		{
			title: "Social",
			span: "Media",

			p: "Lörem ipsum läktigt ödleplåster. Nir hirade sesaskap udir. Milätt krodåren byväns nijöning. Astrock nyvartad: kvasifoni. Polyr ultrament. Jåskade gensax, rede i läplade att pekäv. Mägon epifaktisk alltså rede. Miteren heminyhet. Terudat kafaliga. Bel plande, reack. Dertad äfanera även om konar i krotide. ",
		},
		{
			title: "PayAfrica",
			span: "in the community",

			p: "Lörem ipsum läktigt ödleplåster. Nir hirade sesaskap udir. Milätt krodåren byväns nijöning. Astrock nyvartad: kvasifoni. Polyr ultrament. Jåskade gensax, rede i läplade att pekäv. Mägon epifaktisk alltså rede. Miteren heminyhet. Terudat kafaliga. Bel plande, reack. Dertad äfanera även om konar i krotide. ",
		},
		{
			title: "Features",
			span: "Announcements",

			p: "Lörem ipsum läktigt ödleplåster. Nir hirade sesaskap udir. Milätt krodåren byväns nijöning. Astrock nyvartad: kvasifoni. Polyr ultrament. Jåskade gensax, rede i läplade att pekäv. Mägon epifaktisk alltså rede. Miteren heminyhet. Terudat kafaliga. Bel plande, reack. Dertad äfanera även om konar i krotide. ",
		},
		{
			title: "The",
			span: "Leadership",

			p: "Lörem ipsum läktigt ödleplåster. Nir hirade sesaskap udir. Milätt krodåren byväns nijöning. Astrock nyvartad: kvasifoni. Polyr ultrament. Jåskade gensax, rede i läplade att pekäv. Mägon epifaktisk alltså rede. Miteren heminyhet. Terudat kafaliga. Bel plande, reack. Dertad äfanera även om konar i krotide. ",
		},
	];

	return (
		<div className="container mx-auto">
			<div className="md:min-h-screen">
				<div className="flex flex-col justify-center items-center ">
					{items.map((item) => (
						<div class="w-5/6 lg:w-8/12 mt-5">
							<div class="rounded-lg overflow-hidden">
								<div class="p-5">
									<h2 class="text-center items-center">
										{item.title} <span className="font-bold">{item.span}</span>
									</h2>
								</div>
								<img
									class="lg:h-80 md:h-48 w-full rounded-lg object-cover object-center"
									src={blogImg}
									alt="blog"
								/>
								<div class="mt-6">
									<p class="font-light md:font-normal leading-relaxed mb-3">
										{item.p}
									</p>
									<div class="flex justify-between ">
										<p class="text-black font-medium inline-flex items-center md:mb-2 lg:mb-0">
											20 minutes ago
										</p>
										<Link to={"/"}>
											<p class="font-medium mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 ">
												read more...
											</p>
										</Link>
									</div>
								</div>
								<div className="mt-5">
									<h6 className="text-center text-gray-400">Share this article</h6>
								</div>
								<div className="mb-10 flex space-x-3 place-content-center ">
									<a href="/">
										<ion-icon name="logo-facebook"></ion-icon>
									</a>
									<a href="/">
										<ion-icon name="logo-twitter"></ion-icon>
									</a>
									<a href="/">
										<ion-icon name="logo-instagram"></ion-icon>
									</a>
									<a href="/">
										<ion-icon name="logo-youtube"></ion-icon>
									</a>
								</div>
							</div>
							<hr className="mt-2 size-md" />
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Cards;
