/** @format */

import React from "react";
import NavBar from "../../components/support/NavBar";
import Hero from "../../components/support/Hero";
import Custodian from "../../components/support/Custodian";
import Footer from "../../components/shared/Footer";
import Faq from "../../components/support/Faq";

const Support = () => {
	return (
		<div>
			<NavBar />
			<div className="">
				<div className="divide-y-2 divide-solid divide-gray-300">
					<Hero />
					<Custodian />
					<Faq />
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Support;
