/** @format */

import React from "react";

const AboutUs = () => {
	return (
		<div id="aboutus" className="mt-5 mb-8">
			<h2 className="text-xl mt-5">
				About <span className="font-bold">Us</span>
			</h2>
			<div className="mt-6 mb-3">
				<p className="text-md w-100 md:text-xl font-light">
					Pay<span className="font-bold">Africa</span> makes it easy for people
					in Uganda to access a loan, anytime, anywhere. Complete our
					application in seconds and receive your loan straight onto your Mobile
					money account. Pay<span className="font-bold">Africa</span> is like a
					bank in your pocket, there for you at all times. Pay
					<span className="font-bold">Africa</span> is based in Kampala, Uganda.
				</p>
			</div>
		</div>
	);
};

export default AboutUs;
