import React from 'react'
import NavBar from '../../components/comingSoon/NavBar'
import Hero from '../../components/comingSoon/Hero'
import Footer from '../../components/comingSoon/Footer'

const ComingSoon = () => {
  return (
		<div className="min-h-screen bg-primaryColor">
			<NavBar />
			<Hero />
			<Footer />
		</div>
	);
}

export default ComingSoon
