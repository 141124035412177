/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
	return (
		<div className="container mx-auto">
			<div className="md:min-h-screen">
				<div
					className="hero h-100 bg-container bg-center bg-blend-overlay bg-primaryColor"
					style={{ backgroundImage: `url(/images/bg.jpg)` }}>
					<div className="heading-container absolute flex flex-col justify-center items-center w-full">
						<h1 className="mt-10 text-4xl text-white ">
							Pay<span className="font-bold">Africa</span>
						</h1>
						<p className="text-xl text-white mt-5">
							The official Pay<span className="font-bold">Africa</span> blog
						</p>
						<div className="w-full max-w-2xl"></div>
					</div>
				</div>

				<div className="mx-5 mb-10 md:mx-0 md:mb-0 md:mt-10 flex flex-col justify-center md:items-center w-full">
					<div className="mt-3 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-10">
						<Link to={"/"}>
							<h1 className="text-lg">Social media</h1>
						</Link>
						<Link to={"/"}>
							<h1 className="text-lg">PayAfrica News</h1>
						</Link>
					</div>
					<div className="mt-3 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-10">
						<Link to={"/"}>
							<h1 className="text-lg">Leadership </h1>
						</Link>
						<Link to={"/"}>
							<h1 className="text-lg"> Learn </h1>
						</Link>
						<Link to={"/"}>
							<h1 className="text-lg">Campaigns</h1>
						</Link>
						<Link to={"/"}>
							<h1 className="text-lg">Feature Announcements</h1>
						</Link>
					</div>
					<div className="mt-3 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-10">
						<Link to={"/"}>
							<h1 className="text-lg">PayAfrica in the community</h1>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Hero;
