import React from 'react'
import Hero from "../../components/home/Hero";
import NavBar from "../../components/home/NavBar";
import Procedure from "../../components/home/Procedure";
import AboutUs from "../../components/home/AboutUs";
import Features from "../../components/home/Features"; 
import Footer from '../../components/shared/Footer'

const Home = () => {
    return (
      <>
      <NavBar />
        <div className='mx-5'> <div className='divide-y-2 divide-solid divide-primaryColor'>
         
      <Hero/>
      <Procedure/>
      <AboutUs/>
      <Features/>
        </div>
      </div>
      <Footer/>
      </>
  )
}

export default Home