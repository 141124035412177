/** @format */

import React from "react";
import { Link } from "react-router-dom";

const NavBar = ({ fixed }) => {
	const [navbarOpen, setNavbarOpen] = React.useState(false);

	return (
		<nav className="sticky top-0 z-50 flex flex-wrap items-center justify-between px-4 py-3 bg-primaryColor">
			<div className="container  mx-auto flex flex-wrap items-center justify-between">
				<div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
					<a
						className="text-lg leading-relaxed inline-block mr-4 py-2 whitespace-nowrap  text-white"
						href="/">
						Pay<span className="font-bold">Africa</span>
					</a>
					<button
						className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
						type="button"
						onClick={() => setNavbarOpen(!navbarOpen)}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-6 w-6"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							strokeWidth={2}>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M4 6h16M4 12h16M4 18h16"
							/>
						</svg>
					</button>
				</div>
				<div>
					<div
						className={
							"lg:flex flex-grow items-center" +
							(navbarOpen ? " flex" : " hidden")
						}
						id="example-navbar-danger">
						<ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
							<li className="nav-item">
								<a
									className="px-3 py-2 flex items-center text-md leading-snug text-white hover:opacity-75"
									href="/#features">
									<span className="ml-2">Features</span>
								</a>
							</li>
							<li className="nav-item">
								<a
									className="px-3 py-2 flex items-center text-md leading-snug text-white hover:opacity-75"
									href="/#aboutus">
									<span className="ml-2">About Us</span>
								</a>
							</li>
							<li className="nav-item">
								<Link
									to="/support"
									className="px-3 py-2 flex items-center text-md leading-snug text-white hover:opacity-75">
									<span className="ml-2">Support</span>
								</Link>
							</li>
							<li className="nav-item">
								<Link
									to={"/blogs"}
									className="px-3 py-2 flex items-center text-md leading-snug text-white hover:opacity-75">
									<span className="ml-2">Blog</span>
								</Link>
							</li>
						</ul>
					</div>
				</div>

				<div>
					<div
						className={
							"lg:flex flex-grow items-center" +
							(navbarOpen ? " flex" : " hidden")
						}
						id="example-navbar-danger">
						<ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
							<li className="nav-item">
								<Link to={"/get-started/coming-soon"}>
									<button
										className="ml-2 px-10 py-3 flex items-center text-md leading-snug bg-white text-black hover:opacity-75 rounded"
										href="/">
										Get Started
									</button>
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default NavBar;
