/** @format */

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../../views/Pages/Home";
import Support from "../../views/Pages/Support";
import Blog from "../../views/Pages/Blog";
import SignUp from "../../views/Pages/SignUp";
import SignUpVerify from "../../views/Pages/SignUpVerify";
import ComingSoon from "../../views/Pages/ComingSoon";

const routes = () => {
	return (
		<div>
			<Router>
				<Routes>
					<Route path="/">
						<Route index element={<Home />} />
						<Route path="support" element={<Support />} />
						<Route path="blogs" element={<Blog />} />
						{/* <Route path="signup" element={<SignUp />} /> */}
            <Route path="signup" element={<ComingSoon />} />
						<Route path="get-started/coming-soon" element={<ComingSoon />} />
						<Route path="signup/verification" element={<SignUpVerify />} />
					</Route>
				</Routes>
			</Router>
		</div>
	);
};

export default routes;
