/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
	return (
		<div className="container mx-auto">
			<div className="md:min-h-screen">
				<div
					className="hero h-100 bg-container bg-center bg-blend-overlay bg-primaryColor"
					style={{ backgroundImage: `url(/images/bg.jpg)` }}>
					<div className="heading-container absolute flex flex-col justify-center items-center w-full">
						<h1 className="font-bold text-3xl text-white ">How can we help?</h1>
						<p className="text-lg text-white mt-5">
							Use specific terms for the most relevant results
						</p> 
						<div className="w-full max-w-2xl">
							<form action="submit" className="p-4 mt-5">
								<div className="mt-4 w-full">
									<input
										className="shadow appearance-none border rounded-md w-full p-4 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline text-left"
										type="text"
										placeholder="How can we help you today?"
										required
									/>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div className="mx-2 mb-5 md:mb-0 md:mx-0 mt-3 md:mt-10 flex flex-col md:justify-center md:items-center md:w-full">
					<div className="mt-3 flex flex-col space-y-2 md:flex-row md:space-x-10">
						<Link to={"/"}>
							<h1 className="text-lg">What is PayAfrica?</h1>
						</Link>
						<Link to={"/"}>
							<h1 className="text-lg">How do I register with PayAfrica?</h1>
						</Link>
					</div>
					<div className="mt-3 flex flex-col md:flex-row space-y-2 space-x-10">
						<Link to={"/"}>
							<h1 className="text-lg">
								In what countries does PayAfrica Operate?
							</h1>
						</Link>
					</div>
					<div className="mt-3 flex flex-col md:flex-row space-y-2 md:space-x-10">
						<Link to={"/"}>
							<h1 className="text-lg">How can I apply for a loan?</h1>
						</Link>
						<Link to={"/"}>
							<h1 className="text-lg">How can I issue for a loan?</h1>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Hero;
