/** @format */

import React from "react";

const Faq = () => {
	const items = [
		{
			questions: "What is PayAfrica?",
			ans: "lorem fdasflksnflksnflskdnfsdkjnfsdkfjns",
		},
		{
			questions: "In what countries does PayAfrica operate?",
			ans: "lorem fdasflksnflksnflskdnfsdkjnfsdkfjns",
		},
		{
			questions: "How do I register with PayAfrica?",
			ans: "lorem fdasflksnflksnflskdnfsdkjnfsdkfjns",
		},
		{
			questions: "How can I apply for a loan?",
			ans: "lorem fdasflksnflksnflskdnfsdkjnfsdkfjns",
		},
		{
			questions: "How can I issue out a loan?",
			ans: "lorem fdasflksnflksnflskdnfsdkjnfsdkfjns",
		},
	];

	return (
		<div className="container mx-auto md:min-h-screen">
			<div className="mt-5 px-4">
				<h1 className="text-xl">
					Frequently <span className="font-bold">Asked</span>
				</h1>
				{items.map((item) => (
					<div className="mt-8 md:mx-8">
						<div className="flex justify-between">
							<h1 className="font-bold">{item.questions}</h1>
							<p>+</p>
						</div>
					</div>
				))}
			</div>
			<div className="mt-20 mb-10 md:mb-0 md:mt-48 flex flex-col items-center">
				<h1 className="font-bold">Can't find what you're looking for?</h1>
				<p>Exhausted the custodian and didn't find anything?</p>
				<div>
					<button
						className="mt-4 bg-primaryColor hover:bg-blue-600 w-full text-black  py-3 px-10 rounded-md focus:outline-none focus:shadow-outline text-center"
						type="button">
						Contact Us
					</button>
				</div>
			</div>
		</div>
	);
};

export default Faq;
