/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<div className=" container mx-auto md:mt-11">
			<div>
				<footer className="footer px-4  items-center text-white font-normal">
					<div className="flex flex-col md:grid md:grid-cols-4 md:gap-36 w-full mt-10">
						<div className="text-sm">
							<a href="/#aboutus">About Us</a>
						</div>
						<div className="text-sm">
							<Link to={"/"}>Privacy Policy</Link>
						</div>
						<div className="text-sm">
							<Link to={"/"}>Terms of Use</Link>
						</div>
						<div className="md:place-self-center md:justify-self-end place-content-center">
							<div className="grid grid-flow-col gap-2">
								<h3 className="text-sm">Follow Us:</h3>
								<span className="text-xl space-x-4 font-bold">
									<a href="/" className="size-xl">
										<ion-icon name="logo-facebook"></ion-icon>
									</a>
									<a href="/">
										<ion-icon name="logo-twitter"></ion-icon>
									</a>
									<a href="/">
										<ion-icon name="logo-instagram"></ion-icon>
									</a>
									<a href="/">
										<ion-icon name="logo-youtube"></ion-icon>
									</a>
								</span>
							</div>
						</div>
					</div>
				</footer>
			</div>
		</div>
	);
};

export default Footer;
