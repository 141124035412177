/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<div className="container mx-auto">
			<div className=" divide-y divide-solid divide-white">
				<div>
					<footer className="py-8 bg-black text-base-content">
						<div>
							<div className="flex justify-between items-center px-4 md:py-7 text-base">
								<Link
									to={"/"}
									className="text-lg leading-relaxed inline-block mr-4 py-1 whitespace-nowrap  text-white">
									Pay<span className="font-bold">Africa</span>
								</Link>
								<a href="/" className="text-white">
									How can we help?
								</a>
							</div>
						</div>
						<div className="md:flex md:justify-between md:items-center px-4 text-sm text-gray-300">
							<div className="flex flex-col justify-between space-y-2 md:space-y-0 md:grid md:grid-flow-col md:gap-6">
								<a href="/#features" className=" font-thin">
									Features
								</a>
								<a href="/#aboutus" className=" font-thin">
									About Us
								</a>
								<Link to={"/blogs"} className=" font-thin">
									Blog
								</Link>
							</div>
							<div className="mt-5 md:mt-0 grid grid-flow-col gap-4">
								<a href="/" className=" font-thin">
									Help Docs & FAQs
								</a>
								<span className="font-thin"> | </span>
								<a href="/" className=" font-thin">
									Support via in-App Chat
								</a>
							</div>
						</div>
					</footer>
				</div>
				<div>
					<footer className="footer px-4 py-10 bg-black items-center text-white font-thin">
						<div className="grid grid-cols-3 ">
							<p>
								2022 Copyright. <br />
								Kampala, Uganda
							</p>
						</div>
						<div className="text-sm font-thin">
							<h3>Currently Available Only in Uganda</h3>
						</div>
						<div className="md:place-self-center md:justify-self-end place-content-center">
							<div className="grid grid-flow-col gap-4">
								<h3 className="text-sm font-thin">Follow Us:</h3>
								<span className="text-xl space-x-4 font-bold">
									<a href="/" className="size-xl">
										<ion-icon name="logo-facebook"></ion-icon>
									</a>
									<a href="/">
										<ion-icon name="logo-twitter"></ion-icon>
									</a>
									<a href="/">
										<ion-icon name="logo-instagram"></ion-icon>
									</a>
									<a href="/">
										<ion-icon name="logo-youtube"></ion-icon>
									</a>
								</span>
							</div>
						</div>
					</footer>
				</div>
			</div>
		</div>
	);
};

export default Footer;
